.#{$prefix}-c-footer {
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 30px;
  padding-left: 10px;
  font-size: 14px;
  user-select: none;

  a {
    padding-bottom: 3px;
    border-bottom: 2px solid transparent;
    color: $palette--white;
    outline: none;

    &:hover {
      border-bottom: 2px solid $palette--white;
    }
  }
}
