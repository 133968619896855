.#{$prefix}-c-start {
  min-width: 100%;

  &__heading {
    font-size: 32px;
    text-transform: uppercase;
  }

  &__icon {
    width: 100px;
    height: 100px;
    margin: 20px auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    padding-bottom: 20px;
    font-size: 20px;
  }

  &__description {
    width: 100%;
    max-width: 625px;
    margin: 0 auto;
    line-height: 1.5;

    p {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__start {
    display: inline-block;
    width: 300px;
    padding: 10px;
    border: 5px solid $palette--white;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      background-color: $palette--white;
      color: $palette--black;
    }
  }
}
