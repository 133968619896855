.#{$prefix}-u-type {
  &--typing {
    &::after {
      padding-left: 5px;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      animation-name: blink;
      animation-timing-function: step-end;
      color: $palette--white;
      content: '_';
      font-weight: bold;
    }
  }
}
