.#{$prefix}-c-hand {
  display: flex;
  width: 775px;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 20px;
  margin: 0 auto;
  margin-top: 20px;

  &--disabled {
    opacity: 0.35;
  }

  &__discard {
    position: relative;
    width: 100px;
    height: 130px;
    border: 3px dashed rgba($palette--white, 0.3);
    background-image: url('../resources/img/discard.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
    border-radius: 6px;

    &::before {
      position: relative;
      bottom: 35px;
      display: block;
      color: rgba($palette--white, 0.3);
      content: 'Discard';
      text-align: center;
      text-transform: uppercase;
    }
  }

  &__discard-wrapper {
    will-change: transition;

    &--enter {
      animation-duration: 0.3s;
      animation-iteration-count: 1;
      animation-name: discard-enter;
      animation-timing-function: linear;
    }

    [class*='card'] {
      position: absolute;
      top: -5px;
      left: -5px;
      border: 5px solid $palette--white;
      background-color: $palette--black;
      background-image: url('../resources/img/card-back.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 30px;
      cursor: default;
    }

    &:nth-child(1):nth-last-child(1) {
      [class*='card'] {
        border: 5px solid $palette--white;
      }
    }

    &:nth-child(1):nth-last-child(2),
    &:nth-child(2):nth-last-child(2),
    &:nth-child(3):nth-last-child(2) {
      [class*='card'] {
        border: 5px solid rgba($palette--white, 0.6);
      }
    }

    &:nth-child(1):nth-last-child(3),
    &:nth-child(2):nth-last-child(3) {
      [class*='card'] {
        border: 5px solid rgba($palette--white, 0.4);
      }
    }

    &:nth-child(1):nth-last-child(4) {
      [class*='card'] {
        border: 5px solid rgba($palette--white, 0.2);
      }
    }
  }
}
