.#{$prefix}-c-stats {
  display: flex;
  justify-content: space-evenly;
  font-size: 0;
  white-space: nowrap;

  &__stat {
    display: inline-block;
    width: 100%;
    height: 81px;
    padding: 5px 10px 10px;
    border: 5px solid $palette--white;
    vertical-align: top;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &[data-stat='information'] {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__label {
    display: inline-block;
    font-size: 36px;
    vertical-align: middle;
  }

  &__value {
    position: relative;
    top: 7px;
    float: right;
    font-size: 22px;
    vertical-align: middle;

    &--small {
      top: 10px;
      font-size: 18px;
    }
  }

  &__bar {
    height: 15px;
    margin-top: 5px;
    background-color: $palette--white;

    [data-stat='health'] & {
      background-color: $palette--bittersweet;
    }

    [data-stat='mana'] & {
      background-color: $palette--malibu;
    }

    [data-stat='armor'] & {
      background-color: $palette--mantis;
    }
  }

  &__status-label {
    padding-top: 2px;
    padding-bottom: 5px;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__status-effect {
    text-align: center;
  }

  &__status-icon {
    position: relative;
    top: 8px;
    display: inline-block;
    height: 20px;
    vertical-align: middle;

    img {
      width: auto;
      height: 20px;
    }
  }

  &__status-duration {
    position: relative;
    top: 7px;
    display: inline-block;
    padding-left: 12px;
    font-size: 20px;
    vertical-align: middle;
  }

  &__gold {
    padding-bottom: 5px;
    font-size: 15px;
    text-transform: capitalize;
  }

  &__defeated {
    padding-bottom: 5px;
    font-size: 15px;
    text-transform: capitalize;
  }

  &__level {
    font-size: 15px;
    text-transform: capitalize;
  }
}
