.#{$prefix}-c-creature {
  width: 775px;
  margin: 20px auto;
  font-size: 0;
  white-space: nowrap;

  &__tunnel {
    display: inline-block;
    width: calc(100% - 200px);
    height: 234px;
    background-image: url('../resources/img/dungeon-tunnel.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150px;
    font-size: 18px;
    vertical-align: top;
  }

  &__name {
    display: flex;
    padding-bottom: 10px;
    font-size: 22px;
  }

  &__name-icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    background-image: url('../resources/img/crowned-skull.png');
    background-position: center;
    background-size: contain;
  }

  &__level {
    padding-bottom: 10px;
    font-size: 16px;
    text-transform: capitalize;
  }

  &__attribute {
    display: inline-block;
    min-height: 46px;
    vertical-align: top;

    &:not(:last-child) {
      padding-right: 14px;
      border-right: 3px solid $palette--white;
      margin-right: 5px;
    }
  }

  &__attribute-label {
    padding-bottom: 10px;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__attribute-icon {
    width: 16px;
    height: 16px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__stats {
    display: inline-block;
    width: 200px;
    font-size: 18px;
    vertical-align: top;
  }

  &__stat {
    position: relative;
    padding: 0 5px;
    border: 5px solid $palette--white;
    font-size: 0;
    white-space: nowrap;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__label {
    z-index: 1;
    display: inline-block;
    padding-right: 5px;
    font-size: 32px;
    vertical-align: middle;
  }

  &__bar {
    display: inline-block;
    max-width: 143px;
    height: 18px;
    background-color: $palette--white;
    vertical-align: middle;

    [data-stat='health'] & {
      background-color: $palette--bittersweet;
    }

    [data-stat='mana'] & {
      background-color: $palette--malibu;
    }

    [data-stat='armor'] & {
      background-color: $palette--mantis;
    }
  }

  &__status-label {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__status-effect {
    text-align: center;
  }

  &__status-icon {
    display: inline-block;
    height: 20px;
    margin-bottom: 9px;
    vertical-align: middle;

    img {
      width: auto;
      height: 20px;
    }
  }

  &__status-duration {
    position: relative;
    top: -5px;
    display: inline-block;
    padding-left: 10px;
    font-size: 20px;
    vertical-align: middle;
  }
}
