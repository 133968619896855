.#{$prefix}-c-card {
  position: relative;
  display: flex;
  width: 100px;
  height: 130px;
  flex-direction: column;
  padding: 5px;
  background-color: $palette--white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
  border-radius: 6px;
  color: $palette--black;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: transform 0.1s ease-out;

  &:hover {
    transform: translateY(-10px);
  }

  &--disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &__cost {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    width: 26px;
    height: 26px;
    align-items: center;
    justify-content: center;
    border: 3px solid $palette--white;
    background-color: $palette--malibu;
    border-radius: 100px;
    color: $palette--white;
    font-size: 17px;
    text-shadow: -1px 1px 0 rgba($palette--black, 0.1);
  }

  &__element {
    position: absolute;
    bottom: -10px;
    left: -10px;
    width: 26px;
    height: 26px;
    background-color: $palette--bittersweet;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 14px;
    border-radius: 100px;
  }

  &__armor {
    position: absolute;
    bottom: -10px;
    left: -10px;
    padding: 5px 9px;
    background-color: $palette--bittersweet;
    border-radius: 100px;
    color: $palette--white;
  }

  &__name {
    display: flex;
    width: 100%;
    height: 16px;
    align-items: center;
    justify-content: center;
    text-shadow: -1px 1px 0 rgba($palette--black, 0.1);
  }

  &__effect {
    margin-top: auto;
    color: $palette--bittersweet;
    text-shadow: -1px 1px 0 rgba($palette--black, 0.1);
  }
}
