.#{$prefix}-c-message {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px;
  background-color: rgba($palette--black, 0.9);

  &__container {
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 5px solid $palette--white;
    background-color: $palette--black;
    text-align: center;
  }
}
