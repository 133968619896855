.#{$prefix}-c-shop {
  min-width: 100%;

  &__heading {
    padding-bottom: 5px;
    border-bottom: 5px solid $palette--white;
    margin: 0 30px;
    font-size: 28px;
    text-align: left;
    text-transform: uppercase;
  }

  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 30px;
  }

  &__card-container-bordered {
    padding-left: 33px;
    border-left: 5px solid $palette--white;
  }

  &__card {
    margin: 0 auto 15px;
    pointer-events: none;
  }

  &__card-price {
    padding-bottom: 15px;
    text-transform: capitalize;
  }

  &__card-buy {
    width: 150px;
    padding: 10px;
    border: 5px solid $palette--white;
    margin: 0 auto;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      background-color: $palette--white;
      color: $palette--black;
    }
  }

  &__heal-notice {
    height: 16px;
    margin-top: 20px;
  }

  &__status {
    height: 16px;
    margin-top: 10px;

    span {
      color: $palette--bittersweet;
      text-transform: uppercase;
    }
  }

  &__status--error {
    color: $palette--bittersweet;
  }

  &__continue {
    display: inline-block;
    width: 300px;
    padding: 10px;
    border: 5px solid $palette--white;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      background-color: $palette--white;
      color: $palette--black;
    }
  }
}
