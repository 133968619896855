html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@keyframes blink {
  from, to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes discard-enter {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

@font-face {
  font-display: swap;
  font-family: Fixedsys Excelsior;
  font-style: normal;
  font-weight: 400;
  src: url("fixedsys-excelsior.856ab31d.woff2") format("woff2");
}

::selection {
  color: #1f1f1f;
  opacity: 1;
  background-color: #fffcf4fc;
}

html {
  font-size: 62.5%;
}

body {
  color: #fffcf4;
  font-family: Fixedsys Excelsior, monospace;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1;
}

a {
  text-decoration: none;
}

body {
  min-height: 100vh;
  background-color: #1f1f1f;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.tm-c-board {
  border: 5px solid #fffcf4;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
}

.tm-c-board--hit {
  background-color: #ff00004d;
}

.tm-c-board__creature {
  position: relative;
}

.tm-c-board__hand {
  border-top: 5px solid #fffcf4;
}

.tm-c-board__message {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.tm-c-board__message--active {
  display: block;
}

.tm-c-card {
  width: 100px;
  height: 130px;
  color: #1f1f1f;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  background-color: #fffcf4;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
  border-radius: 6px;
  flex-direction: column;
  padding: 5px;
  transition: transform .1s ease-out;
  display: flex;
  position: relative;
}

.tm-c-card:hover {
  transform: translateY(-10px);
}

.tm-c-card--disabled {
  opacity: .4;
  pointer-events: none;
}

.tm-c-card__cost {
  width: 26px;
  height: 26px;
  color: #fffcf4;
  text-shadow: -1px 1px #1f1f1f1a;
  background-color: #729afd;
  border: 3px solid #fffcf4;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  display: flex;
  position: absolute;
  top: -10px;
  right: -10px;
}

.tm-c-card__element {
  width: 26px;
  height: 26px;
  background-color: #fd7272;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  border-radius: 100px;
  position: absolute;
  bottom: -10px;
  left: -10px;
}

.tm-c-card__armor {
  color: #fffcf4;
  background-color: #fd7272;
  border-radius: 100px;
  padding: 5px 9px;
  position: absolute;
  bottom: -10px;
  left: -10px;
}

.tm-c-card__name {
  width: 100%;
  height: 16px;
  text-shadow: -1px 1px #1f1f1f1a;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tm-c-card__effect {
  color: #fd7272;
  text-shadow: -1px 1px #1f1f1f1a;
  margin-top: auto;
}

.tm-c-container {
  width: 900px;
  margin: 0 auto;
}

.tm-c-creature {
  width: 775px;
  white-space: nowrap;
  margin: 20px auto;
  font-size: 0;
}

.tm-c-creature__tunnel {
  width: calc(100% - 200px);
  height: 234px;
  vertical-align: top;
  background-image: url("dungeon-tunnel.0974e5f4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150px;
  font-size: 18px;
  display: inline-block;
}

.tm-c-creature__name {
  padding-bottom: 10px;
  font-size: 22px;
  display: flex;
}

.tm-c-creature__name-icon {
  width: 22px;
  height: 22px;
  background-image: url("crowned-skull.8579f3e0.png");
  background-position: center;
  background-size: contain;
  margin-right: 10px;
  display: inline-block;
}

.tm-c-creature__level {
  text-transform: capitalize;
  padding-bottom: 10px;
  font-size: 16px;
}

.tm-c-creature__attribute {
  min-height: 46px;
  vertical-align: top;
  display: inline-block;
}

.tm-c-creature__attribute:not(:last-child) {
  border-right: 3px solid #fffcf4;
  margin-right: 5px;
  padding-right: 14px;
}

.tm-c-creature__attribute-label {
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 14px;
}

.tm-c-creature__attribute-icon {
  width: 16px;
  height: 16px;
}

.tm-c-creature__attribute-icon img {
  width: 100%;
  height: 100%;
}

.tm-c-creature__stats {
  width: 200px;
  vertical-align: top;
  font-size: 18px;
  display: inline-block;
}

.tm-c-creature__stat {
  white-space: nowrap;
  border: 5px solid #fffcf4;
  padding: 0 5px;
  font-size: 0;
  position: relative;
}

.tm-c-creature__stat:not(:last-child) {
  margin-bottom: 15px;
}

.tm-c-creature__label {
  z-index: 1;
  vertical-align: middle;
  padding-right: 5px;
  font-size: 32px;
  display: inline-block;
}

.tm-c-creature__bar {
  max-width: 143px;
  height: 18px;
  vertical-align: middle;
  background-color: #fffcf4;
  display: inline-block;
}

[data-stat="health"] .tm-c-creature__bar {
  background-color: #fd7272;
}

[data-stat="mana"] .tm-c-creature__bar {
  background-color: #729afd;
}

[data-stat="armor"] .tm-c-creature__bar {
  background-color: #74c572;
}

.tm-c-creature__status-label {
  text-transform: uppercase;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.tm-c-creature__status-effect {
  text-align: center;
}

.tm-c-creature__status-icon {
  height: 20px;
  vertical-align: middle;
  margin-bottom: 9px;
  display: inline-block;
}

.tm-c-creature__status-icon img {
  width: auto;
  height: 20px;
}

.tm-c-creature__status-duration {
  vertical-align: middle;
  padding-left: 10px;
  font-size: 20px;
  display: inline-block;
  position: relative;
  top: -5px;
}

.tm-c-dungeon {
  -webkit-user-select: none;
  user-select: none;
  padding: 10px;
}

.tm-c-footer {
  -webkit-user-select: none;
  user-select: none;
  padding: 5px 10px 30px;
  font-size: 14px;
}

.tm-c-footer a {
  color: #fffcf4;
  border-bottom: 2px solid #0000;
  outline: none;
  padding-bottom: 3px;
}

.tm-c-footer a:hover {
  border-bottom: 2px solid #fffcf4;
}

.tm-c-hand {
  width: 775px;
  justify-content: space-between;
  margin: 20px auto 0;
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
}

.tm-c-hand--disabled {
  opacity: .35;
}

.tm-c-hand__discard {
  width: 100px;
  height: 130px;
  background-image: url("discard.2d3416af.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  border: 3px dashed #fffcf44d;
  border-radius: 6px;
  position: relative;
}

.tm-c-hand__discard:before {
  color: #fffcf44d;
  content: "Discard";
  text-align: center;
  text-transform: uppercase;
  display: block;
  position: relative;
  bottom: 35px;
}

.tm-c-hand__discard-wrapper {
  will-change: transition;
}

.tm-c-hand__discard-wrapper--enter {
  animation-name: discard-enter;
  animation-duration: .3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.tm-c-hand__discard-wrapper [class*="card"] {
  cursor: default;
  background-color: #1f1f1f;
  background-image: url("card-back.e504cf08.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  border: 5px solid #fffcf4;
  position: absolute;
  top: -5px;
  left: -5px;
}

.tm-c-hand__discard-wrapper:nth-child(1):nth-last-child(1) [class*="card"] {
  border: 5px solid #fffcf4;
}

.tm-c-hand__discard-wrapper:nth-child(1):nth-last-child(2) [class*="card"], .tm-c-hand__discard-wrapper:nth-child(2):nth-last-child(2) [class*="card"], .tm-c-hand__discard-wrapper:nth-child(3):nth-last-child(2) [class*="card"] {
  border: 5px solid #fffcf499;
}

.tm-c-hand__discard-wrapper:nth-child(1):nth-last-child(3) [class*="card"], .tm-c-hand__discard-wrapper:nth-child(2):nth-last-child(3) [class*="card"] {
  border: 5px solid #fffcf466;
}

.tm-c-hand__discard-wrapper:nth-child(1):nth-last-child(4) [class*="card"] {
  border: 5px solid #fffcf433;
}

.tm-c-log {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 15px;
}

.tm-c-log__container {
  width: calc(100% - 300px);
  height: 100px;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 49px;
  line-height: 1.3;
  display: flex;
  overflow: hidden;
}

.tm-c-log__entry span {
  color: #fd7272;
  text-transform: uppercase;
}

.tm-c-log__entry:not(:last-child) {
  padding-bottom: 5px;
}

.tm-c-log__entry:last-child:not([class*="typing"]):after {
  color: #fffcf4;
  content: "_";
  padding-left: 5px;
  font-weight: bold;
  animation-name: blink;
  animation-duration: 1.5s;
  animation-timing-function: step-end;
  animation-iteration-count: infinite;
}

.tm-c-log__death, .tm-c-log__advance {
  color: #fd7272;
}

.tm-c-message {
  height: 100%;
  background-color: #1f1f1fe6;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px;
  display: flex;
}

.tm-c-message__container {
  min-height: 100%;
  text-align: center;
  background-color: #1f1f1f;
  border: 5px solid #fffcf4;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.tm-c-death {
  min-width: 100%;
}

.tm-c-death__heading {
  text-transform: uppercase;
  font-size: 32px;
}

.tm-c-death__icon {
  width: 100px;
  height: 95px;
  margin: 30px auto;
}

.tm-c-death__icon img {
  width: 100%;
}

.tm-c-death__level {
  padding-bottom: 5px;
  font-size: 18px;
}

.tm-c-death__gold {
  font-size: 18px;
}

.tm-c-death__restart {
  width: 300px;
  cursor: pointer;
  text-transform: uppercase;
  border: 5px solid #fffcf4;
  margin-top: 20px;
  padding: 10px;
  display: inline-block;
}

.tm-c-death__restart:hover {
  color: #1f1f1f;
  background-color: #fffcf4;
}

.tm-c-shop {
  min-width: 100%;
}

.tm-c-shop__heading {
  text-align: left;
  text-transform: uppercase;
  border-bottom: 5px solid #fffcf4;
  margin: 0 30px;
  padding-bottom: 5px;
  font-size: 28px;
}

.tm-c-shop__cards {
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 30px;
  display: flex;
}

.tm-c-shop__card-container-bordered {
  border-left: 5px solid #fffcf4;
  padding-left: 33px;
}

.tm-c-shop__card {
  pointer-events: none;
  margin: 0 auto 15px;
}

.tm-c-shop__card-price {
  text-transform: capitalize;
  padding-bottom: 15px;
}

.tm-c-shop__card-buy {
  width: 150px;
  cursor: pointer;
  text-transform: uppercase;
  border: 5px solid #fffcf4;
  margin: 0 auto;
  padding: 10px;
}

.tm-c-shop__card-buy:hover {
  color: #1f1f1f;
  background-color: #fffcf4;
}

.tm-c-shop__heal-notice {
  height: 16px;
  margin-top: 20px;
}

.tm-c-shop__status {
  height: 16px;
  margin-top: 10px;
}

.tm-c-shop__status span {
  color: #fd7272;
  text-transform: uppercase;
}

.tm-c-shop__status--error {
  color: #fd7272;
}

.tm-c-shop__continue {
  width: 300px;
  cursor: pointer;
  text-transform: uppercase;
  border: 5px solid #fffcf4;
  margin-top: 20px;
  padding: 10px;
  display: inline-block;
}

.tm-c-shop__continue:hover {
  color: #1f1f1f;
  background-color: #fffcf4;
}

.tm-c-start {
  min-width: 100%;
}

.tm-c-start__heading {
  text-transform: uppercase;
  font-size: 32px;
}

.tm-c-start__icon {
  width: 100px;
  height: 100px;
  margin: 20px auto;
}

.tm-c-start__icon img {
  width: 100%;
  height: 100%;
}

.tm-c-start__name {
  padding-bottom: 20px;
  font-size: 20px;
}

.tm-c-start__description {
  width: 100%;
  max-width: 625px;
  margin: 0 auto;
  line-height: 1.5;
}

.tm-c-start__description p {
  margin: 0;
}

.tm-c-start__description p:not(:last-child) {
  margin-bottom: 10px;
}

.tm-c-start__start {
  width: 300px;
  cursor: pointer;
  text-transform: uppercase;
  border: 5px solid #fffcf4;
  margin-top: 20px;
  padding: 10px;
  display: inline-block;
}

.tm-c-start__start:hover {
  color: #1f1f1f;
  background-color: #fffcf4;
}

.tm-c-treasure {
  min-width: 100%;
}

.tm-c-treasure__heading {
  text-align: left;
  text-transform: uppercase;
  border-bottom: 5px solid #fffcf4;
  margin: 0 30px;
  padding-bottom: 5px;
  font-size: 28px;
}

.tm-c-treasure__cards {
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 30px;
  display: flex;
}

.tm-c-treasure__card-container-bordered {
  border-left: 5px solid #fffcf4;
  padding-left: 33px;
}

.tm-c-treasure__card {
  pointer-events: none;
  margin: 0 auto 12px;
}

.tm-c-treasure__card-price {
  height: 16px;
  text-transform: capitalize;
  margin-bottom: 12px;
}

.tm-c-treasure__card-take {
  width: 150px;
  cursor: pointer;
  text-transform: uppercase;
  border: 5px solid #fffcf4;
  margin: 0 auto;
  padding: 10px;
}

.tm-c-treasure__card-take:hover {
  color: #1f1f1f;
  background-color: #fffcf4;
}

.tm-c-treasure__heal-notice {
  height: 16px;
  margin-top: 20px;
}

.tm-c-treasure__status {
  height: 16px;
  margin-top: 10px;
}

.tm-c-treasure__continue {
  width: 300px;
  cursor: pointer;
  text-transform: uppercase;
  border: 5px solid #fffcf4;
  margin-top: 20px;
  padding: 10px;
  display: inline-block;
}

.tm-c-treasure__continue:hover {
  color: #1f1f1f;
  background-color: #fffcf4;
}

.tm-c-stats {
  white-space: nowrap;
  justify-content: space-evenly;
  font-size: 0;
  display: flex;
}

.tm-c-stats__stat {
  width: 100%;
  height: 81px;
  vertical-align: top;
  border: 5px solid #fffcf4;
  padding: 5px 10px 10px;
  display: inline-block;
}

.tm-c-stats__stat:not(:last-child) {
  margin-right: 20px;
}

.tm-c-stats__stat[data-stat="information"] {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.tm-c-stats__label {
  vertical-align: middle;
  font-size: 36px;
  display: inline-block;
}

.tm-c-stats__value {
  float: right;
  vertical-align: middle;
  font-size: 22px;
  position: relative;
  top: 7px;
}

.tm-c-stats__value--small {
  font-size: 18px;
  top: 10px;
}

.tm-c-stats__bar {
  height: 15px;
  background-color: #fffcf4;
  margin-top: 5px;
}

[data-stat="health"] .tm-c-stats__bar {
  background-color: #fd7272;
}

[data-stat="mana"] .tm-c-stats__bar {
  background-color: #729afd;
}

[data-stat="armor"] .tm-c-stats__bar {
  background-color: #74c572;
}

.tm-c-stats__status-label {
  text-transform: uppercase;
  padding-top: 2px;
  padding-bottom: 5px;
  font-size: 14px;
}

.tm-c-stats__status-effect {
  text-align: center;
}

.tm-c-stats__status-icon {
  height: 20px;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  top: 8px;
}

.tm-c-stats__status-icon img {
  width: auto;
  height: 20px;
}

.tm-c-stats__status-duration {
  vertical-align: middle;
  padding-left: 12px;
  font-size: 20px;
  display: inline-block;
  position: relative;
  top: 7px;
}

.tm-c-stats__gold, .tm-c-stats__defeated {
  text-transform: capitalize;
  padding-bottom: 5px;
  font-size: 15px;
}

.tm-c-stats__level {
  text-transform: capitalize;
  font-size: 15px;
}

.tm-c-timer {
  max-width: 100%;
  height: 10px;
  background-color: #fffcf4;
  transition: width 50ms linear;
}

.tm-c-title {
  padding-bottom: 20px;
  padding-left: 13px;
  font-size: 36px;
}

.tm-u-type--typing:after {
  color: #fffcf4;
  content: "_";
  padding-left: 5px;
  font-weight: bold;
  animation-name: blink;
  animation-duration: .5s;
  animation-timing-function: step-end;
  animation-iteration-count: infinite;
}

/*# sourceMappingURL=application.caaf2b16.css.map */
