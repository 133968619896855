.#{$prefix}-c-log {
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 100px;

  &__container {
    display: flex;
    overflow: hidden;
    width: calc(100% - 300px);
    height: 100px;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 49px;
    line-height: 1.3;
  }

  &__entry {
    span {
      color: $palette--bittersweet;
      text-transform: uppercase;
    }

    &:not(:last-child) {
      padding-bottom: 5px;
    }

    &:last-child {
      &:not([class*='typing']) {
        &::after {
          padding-left: 5px;
          animation-duration: 1.5s;
          animation-iteration-count: infinite;
          animation-name: blink;
          animation-timing-function: step-end;
          color: $palette--white;
          content: '_';
          font-weight: bold;
        }
      }
    }
  }

  &__death {
    color: $palette--bittersweet;
  }

  &__advance {
    color: $palette--bittersweet;
  }
}
