html {
  font-size: 62.5%;
}

body {
  color: $palette--white;
  font-family: $type--mono;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1;
}
