.#{$prefix}-c-board {
  position: relative;
  padding: 10px;
  border: 5px solid $palette--white;
  margin-bottom: 10px;

  &--hit {
    background-color: rgba($palette--red, 0.3);
  }

  &__creature {
    position: relative;
  }

  &__hand {
    border-top: 5px solid $palette--white;
  }

  &__message {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
  }

  &__message--active {
    display: block;
  }
}
